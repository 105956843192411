"use client";

import { cn } from "@/lib/utils";
import NumberFlow from "@number-flow/react";
import { useEffect, useState } from "react";

const getTotal = (count: number, countAt: Date, ratePerHour: number) =>
  Math.floor(count + ((new Date().getTime() - countAt.getTime()) / 1000 / 60 / 60) * ratePerHour);

export function ModerationsCount({
  count,
  countAt,
  ratePerHour,
}: {
  count: number;
  countAt: Date;
  ratePerHour: number;
}) {
  const [total, setTotal] = useState(count); // don't use getTotal to ensure hydration doesn't rely on current time

  useEffect(() => {
    const interval = setInterval(() => {
      setTotal(getTotal(count, countAt, ratePerHour));
    }, 1000);
    return () => clearInterval(interval);
  }, [count, countAt, ratePerHour]);

  return <NumberFlow className={cn(total === count && "invisible")} value={total} locales="en-US" />;
}
